@import "../_colors";

.exporting-pdf {
  .job {
    .stage-container {
      overflow-x: hidden !important;
    }
  }

  .job-application {
    box-shadow: none !important;
  }

}
