@import 'mixins';

// dynamically generated class names via JS
.activity-label {
  display: inline-block;
  margin: 0;
  font-family: $font-family--secondary;
  font-size: px-to-rem(13px);
  white-space: nowrap;
}

.activity-label-good {
  color: $cq-positive-600;
  background-color: $cq-positive-50;
}

.activity-label-fair {
  color: $cq-warning-600;
  background-color: $cq-warning-100;
}

.activity-label-poor {
  color: $cq-negative-600;
  background-color: $cq-negative-50;
}

.activity-label-inactive {
  color: $cq-neutral-600;
  background-color: $cq-neutral-50;
}

.activity-label-scheduled {
  color: $tw--blue;
  background-color: $cq-primary-50;;
}
