.twng-select {
  $paddingX: 0.5rem;
  $arrowPos: 7px;
  width: 100%;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("/assets/select-chevron-down-solid.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: calc(100% - #{$arrowPos});
  background-position-y: calc(100% - #{$arrowPos});
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  padding: 0.20rem $paddingX;
  padding-right: calc(#{$paddingX} + #{$arrowPos} * 2);
  font-size: 0.875rem;
  line-height: 1.5;
}
