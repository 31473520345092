@import 'mixins';
@import 'vars';
@import '_colors';

.badge--number,
.badge--notification {
  display: flex;
  justify-content: center;
  padding: 0.15rem 0.35rem;
  margin-right: 0.3rem;
  height: 19px;
  font-family: $font-family--secondary;
  font-size: 0.9rem;
  color: white;
  background-color: $tw--blue;
  border-radius: 99rem;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.01rem;
}

.badge--notification {
  padding: 0.09rem 0.35rem;
  height: 18px;
  font-size: 0.8rem;
}
