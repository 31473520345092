@import '_colors';
@import 'vars';
@import 'mixins';

// width declaration not needed. toastr will grow based on it's content
.toast-container {
  &.toast-top-right {
    top: 1rem;
    right: 1rem;
  }

  &.toast-top-center .toast,
  &.toast-bottom-center .toast {
    margin-left: auto;
    margin-right: auto;
  }

  .ngx-toastr {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;

    width: auto !important;
    background-position: 24px 17px !important;
    background-size: 22px !important;

    // leave space on the left side for the icon
    padding: 0.75rem 1.5rem 0.75rem 4.2rem !important;
    user-select: none !important;
    transition: 0.2s ease-out box-shadow, 0.2s ease-out background-color;

    &:hover {
      box-shadow: none;
    }

    &.toast-success {
      background-color: $tw--green;

      // optical alignment - push the check icon into place
      background-position: 24px 16px !important;
    }

    &.toast-success:hover {
      box-shadow: box-shadow-as-border($all-color: lighten($tw--green, 10%));
      background-color: darken($tw--green, 10%)
    }

    &.toast-info {
      background-color: $tw--blue;
    }

    &.toast-info:hover {
      box-shadow: box-shadow-as-border($all-color: lighten($tw--blue, 10%));
      background-color: darken($tw--blue, 10%)
    }

    &.toast-error:hover {
      box-shadow: box-shadow-as-border($all-color: darken($tw--red, 10%));
      background-color: lighten($tw--red, 1%)
    }

    &.toast-warning:hover {
      box-shadow: box-shadow-as-border($all-color: lighten($tw--yellow, 10%));
      background-color: darken($tw--yellow, 10%)
    }

    // using CSS flexbox to reorder the elements
    .toast-title {
      order: 1;
      margin-right: 1rem;
      font-family: $font-family--secondary;
      font-size: px-to-rem(18px);
      font-weight: 500 !important;
    }

    .toast-message {
      order: 2;
      margin-right: auto;
      padding-right: 1.5rem;
      font-family: $font-family--secondary;
      font-size: px-to-rem(18px);
      font-weight: 500 !important;
    }

    .toast-close-button {
      order: 3;
      position: relative !important;
      top: -1px !important;
      float: none !important;
      right: 0 !important;
      font-size: 1.9rem !important;
      line-height: 1 !important;
    }
  }
}
