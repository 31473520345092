@import '_colors';
@import 'utility';
@import 'mixins';

twng-root {
  // @todo: update with transform, instead of left/top for better performance

  $nav-button-size: 42px;
  $nav-avatar-size: 40px;

  // @todo: extract to shared var
  $nav-line-height: 4px;

  #containerLogo {
    margin-left: 1.5rem;
    height: 100%;
  }

  .header__wrapper {
    display: flex;
    width: 100%;

    position: sticky;
    border-bottom: 1px solid #e1e1e1;
    z-index: 100;
    top: 0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.07);
  }

  .header__nav {
    display: flex;
    position: relative;
    z-index: 1;
    height: 3.6rem;
    width: 100vw;
    color: $cq-blue;
    background-color: $cq-stormy;
  }


  .nav__logo-image--blue {
    height: 40px;
    margin-left: 1.5rem;
    // makes image look sharper
    image-rendering: optimizecontrast;
    image-rendering: -webkit-optimize-contrast;
  }

  .nav__links {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    justify-content: flex-end;
    list-style: none;
  }

  .nav__item {
    margin: 0 0.3rem;
  }

  .nav__link {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    user-select: none;
    cursor: pointer;
    padding: 0 1rem;
    transition: all 0.05s ease-out;
  }

  .nav__link:hover {
    text-decoration: none;
  }

  .nav__link--hoverable.active {
    /*
    Using border would push the element, use this instead
    Can't have negative SCSS var #'s, so multiply by -1
  */
    box-shadow: inset 0 calc(-1 * #{$nav-line-height}) 0 0 $cq-blue;
  }

  .nav__link--hoverable:hover {
    // using border would push the element down. use this instead
    box-shadow: inset 0 calc(-1 * #{$nav-line-height}) 0 0 rgba($cq-blue, 0.8);
  }

  .nav__link-icon {
    color: $cq-blue;
    font-size: 1.1rem;

    @include media-breakpoint-up(md) {
      margin: 0 0.75rem 0 0;
    }
  }

  .nav__link-text {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;

      // optically aligning the icon with the text baseline
      position: relative;
      top: 1px;

      color: white;
    }
  }

  .nav__user-dropdown-wrapper {
    display: flex;
    align-items: center;
  }

  .nav__user-info {
    padding: 1rem;
  }

  .nav__user-email {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;
      color: white;
    }
  }

  .nav__user-org {
    margin: 0 0.5rem;
    text-transform: uppercase;
    color: #999;
    font-size: px-to-rem(15px);
  }

  .nav__avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    width: $nav-avatar-size;
    height: $nav-avatar-size;
    padding-top: 0.1rem;
    margin: 0 0 0 0.75rem;
    vertical-align: middle;

    background-color: #888;
    background-size: contain;
    color: white;
    border-radius: 50%;
    transition: all 0.05s ease-out;
  }

  .nav__avatar:hover {
    opacity: 0.8;
  }

  .nav__dropdown--user .dropdown-toggle {
    // @todo: redesign outline and show for accessibility
    outline: 0;
  }

  .nav__dropdown--user .dropdown-toggle::after {
    display: none;
  }

  .nav__dropdown--user .dropdown-menu {
    box-shadow: 0 0 10px 0 #0000001c;
  }

  .nav__dropdown--user .dropdown-item {
    padding: 0.4rem 1.5rem;
    outline: 0;

    &:active,
    &:focus {
      color: $tw--black;
      background-color: #f8f9fa;
    }
  }
}
