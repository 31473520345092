@import 'vars';
@import 'mixins';

// show the breakpoint. Useful for debugging reponsive design
// used in development only.

.breakpoint {
  // comment this line to display the media query helper
  // display: none

  // position: absolute;
  position: fixed;

  z-index: 1000000;
  right: 0;
  bottom: 0;
  padding: 0.3rem 1em;
  opacity: 0.75;
  color: white;
  border-top-left-radius: 0.75rem;

  @include media-breakpoint-only(xs) { background-color: red; }
  @include media-breakpoint-only(sm) { background-color: blue; }
  @include media-breakpoint-only(md) { background-color: green; }
  @include media-breakpoint-only(lg) { background-color: yellow; color: black; }
  @include media-breakpoint-only(xl) { background-color: pink; color: black;  }
}

// corresponds to breakpoints in mediaqueries.scss
.breakpoint:after {
  font-family: $font-family--secondary;
  font-size: px-to-rem(20px);
  font-weight: 400;

  @include media-breakpoint-only(xs) { content: 'XS'; }
  @include media-breakpoint-only(sm) { content: 'SM'; }
  @include media-breakpoint-only(md) { content: 'MD'; }
  @include media-breakpoint-only(lg) { content: 'LG'; }
  @include media-breakpoint-only(xl) { content: 'XL'; }
}
