@import '_colors';

.twng-feature-highlight .tooltip-inner {
  background-color: $tw--green;
  padding: 15px;
  font-size: 20px;
  font-weight: 500;
}

.twng-feature-highlight .arrow::before {
  border-top-color: $tw--green;
  border-bottom-color: $tw--green;
}
