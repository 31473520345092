@import 'mixins';
@import '_colors';

.export-pdf-header {
  margin: 2rem 2rem 0 2rem;
  padding: 1rem 0;
  font-size: px-to-rem(20px);
  color: $tw--blue;
  border-bottom: 1px solid $tw--gray-400;
  font-weight: bold;
}

.export-pdf-jobs-count {
  font-family: $font-family--secondary;
  font-size: px-to-rem(16px);
}

.export-pdf-count-separator {
  margin: 0 0.35rem;
  color: $tw--gray-400;
  user-select: none;
}
