@import '_colors';
@import 'mixins';

html {
  overflow-y: auto;
}

twng-root {
/*
  Global app styles
*/
  a.icon {
    text-decoration: none;
  }

  a.icon:hover {
    text-decoration: none;
  }

  a.icon:active {
    text-decoration: none;
  }

  /* Since dialog boxes are not destroyed,
  the close button comes back focused by default when they are re-opened */

  .close:not(:disabled):not(.disabled):focus {
    outline: none;
  }

  .page__header {
    padding: 1rem 0;
    margin: 1rem 0 1rem 2rem;
    font-family: $font-family--primary;
    font-size: px-to-rem(28px);
    font-weight: 400;
    color: #626262;
  }
}

// scrollbar look

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(150, 150, 150);
}

.confirm-btn-container  {
  .btn-danger {
    background-color: #dc3545;
  }
  .btn-secondary {
    background-color: #6c757d;
  }
}

.btn-danger[type=submit] {
  background-color: #dc3545;
}

.hide-scrollbar {
  // Hide main scrollbar to see iframe one
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  &::-webkit-scrollbar {
    display: none !important; /* Chrome and Safari */
  }
}

.candidates-by-stage-container {
  padding: 1rem 2rem;
  box-shadow: box-shadow-as-border($b: true,
      $b-color: $tw--gray-300,
    );
  background-color: $tw--gray-50;
}

.app-break-word {
  word-break: break-word;
}
