@import 'mixins';
@import 'vars';
@import '_colors';

$padding-sides: 1.75rem;

/*
  Our standardized application menu.
  This is a layer of styles on top of the Bootstrap ngbDropdownMenu

  Note other multiselects may be inside here, which also produces
  .dropdown-menu and .dropdown-item, ie, a multiselect, or date picker.

  The multiselect component via .multiselect__wrapper,
  has higher specifity to override its own
  .dropdown-menu and .dropdown-item classes
*/

/* override ngbDropdownToggle, aka .dropdown-toggle */
.app__dropdown-toggle {
  // make the toggle look like our standard icon button
  @extend .actions-button;

  // hide Bootstrap's default down arrow
  &::after { display: none !important; }
}

/* override ngbDropdownMenu, aka .dropdown-menu */
.app__dropdown-menu {
  position: absolute;
  float: none;
  width: auto;
  // min-width: 400px;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  // @todo: make var
  box-shadow: 0 0 10px 0 #0000001c;

  // @todo: use something better than an arbitrary #
  z-index: 100000;

  /* override ngbDropdownItem */
  .dropdown-item {
    width: 100%;
    padding: 0;
    font-family: $font-family--primary;
    font-size: px-to-rem(14px);
    font-weight: 400;
    color: $tw--gray-700;
    outline: 0;
    user-select: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .section--with-separator {
    padding: 0 0 0.4rem 0;
    border-bottom: 1px solid $tw--gray-300;
  }

  .section--with-separator:last-child {
    border-bottom: none;
    padding-bottom: 1rem;
  }

  // @todo: extract shared
  .section__header {
    display: flex;
    width: 100%;
    padding: 1.5rem $padding-sides 0.25rem $padding-sides;
    font-family: $font-family--secondary;
    font-size: px-to-rem(13px);
    color: $tw--gray-700;
    text-transform: uppercase;
    user-select: none;
  }

  .section__row {
    display: flex;
    width: 100%;
    padding: 0.4rem $padding-sides;

    &:active,
    &:focus {
      background-color: $tw--gray-100;
    }
  }

  .section__row--button {
    @extend %remove-button-styling;

    width: 100%;
    padding: 0.4rem $padding-sides;
    outline-offset: 0;

    &:hover {
      background-color: $tw--gray-100;
    }
  }

  .section__row--forms {
    padding: 0;

    &:hover {
      background-color: $tw--gray-100;
    }
  }

  .section__row--actions {
    padding: 1rem $padding-sides 0.25rem $padding-sides;
  }

  // can't use flex on <button>, use a wrapper inside
  .button-flex-wrapper {
    display: flex;
    align-items: center;
  }

  .section__row-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    margin-right: 0.5rem;

    // for both font awesome and our own SVG icons
    color: $tw--gray-800;
    svg { fill: $tw--gray-800; }
  }

  .section__row-label {
    font-family: $font-family--primary;
    font-size: px-to-rem(16px);
    color: $tw--black;
  }

  // overriding
  .multiselect__wrapper {
    border: 0;
    border-bottom: 1px solid $tw--gray-300;
    padding: 0.5rem 0.5rem 0 $padding-sides;
  }
}