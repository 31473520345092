@use "sass:math";

@import 'vars';
@import '_colors';

// modified from:
// https://github.com/stubbornella/oocss/blob/master/oocss/src/components/utils/_fontSize.scss
@function px-to-rem($size) {
  $remSize: math.div($size, $html-font-size);
  @return #{$remSize}rem;
}

@mixin truncate ($truncation-boundary) {
  overflow: hidden;
  max-width: $truncation-boundary;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%remove-button-styling {
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
}

%multiselect-wrapper {
  display: inline-flex;
  flex-wrap: wrap;

  /*
    Default to full width, with no max size.
    If a specific width or max-size is needed,
    set it on the instance, or the instance's parent
  */
  flex-grow: 1;
  width: 100%;

  // So form doesn't shrink when there are no tags
  min-height: $app-form-height;

  // no bottom padding, because the tags will push the multiselect down
  padding: $multiselect-spacing $multiselect-spacing 0 $multiselect-spacing;

  vertical-align: middle;
  line-height: 1.4;
  border: 1px solid $tw--gray-300;
  cursor: text;
  white-space: normal;
  user-select: none;
}

%multiselect-input {
  // so clicking anywhere inside the form will open the autocomplete
  flex-grow: 1;

  /*
    This needs a value so the flex-grow works,
    without wrapping to the next line. Best solution I found.

    A fixed width should not be set here, but on the parent, the multiselect-wrapper
  */
  // width: 1px;
  width: 50px; // need to use a wider input than 1px, or it becomes hard to click as it fills

  // adjust for vertical centering of placeholder text
  position: relative;
  top: -2px;

  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
}

%multiselect-tag {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-right: $multiselect-spacing;
  margin-bottom: $multiselect-spacing;
  padding: 0 0 0 0.75rem;
  font-size: px-to-rem(15px);
  line-height: 2;
  color: $tw--black;
  background-color: $tw--gray-300;
  border: 1px solid $tw--gray-350;
  border-radius: 0.25rem;
  user-select: none;
  cursor: initial;
  transition: all 0.1s ease-out;

  &:hover {
    border: 1px solid $tw--gray-350;
    background-color: $tw--gray-200;

    i {
      color: $tw--gray-800;
    }
  }
}

/*
  Why
    Using the `border` CSS property shifts the position of the
    element when hovering over/focusing it. To prevent this, we
    use transparent borders on the resting state.

    Using `box-shadow` is a simpler solution, because the
    rendered lines don't affect the box model

  Params:

    Pass in `true` for each side of the element that needs a border.
    A color is optional per side with `$[side]-color`

    If no args are passed in, a border on all sides is rendered.
    A color is optional with `$all-color`

    Where to render the border:
      `true`: inside the element (default)
      `false`: outside the element

    Examples:
      // left, red border, on the inside
      box-shadow: box-shadow-as-border($l: true, $l-color: red)
        or
      box-shadow: box-shadow-as-border($l: true, $l-color: red, $inset: true)

      // bottom gray border + top blue border, on the outside
      box-shadow: box-shadow-as-border(
        $t: true,
        $t-color: blue,

        $b: true,
        $b-color: #ccc,

        $inset: false)

      // border on all sides, defaults to black, on the inside
      box-shadow: box-shadow-as-border()
*/
@function box-shadow-as-border($l: null,
  $l-color: #000,
  $l-size: 1px,

  $r: null,
  $r-color: #000,
  $r-size: 1px,

  $t: null,
  $t-color: #000,
  $t-size: 1px,

  $b: null,
  $b-color: #000,
  $b-size: 1px,

  $all-color: #000,
  $all-size: 1px,
  $inset: true) {

  $l-string: null;
  $r-string: null;
  $t-string: null;
  $b-string: null;
  $list: ();

  // at least one of the sides is specified
  @if $l or $r or $b or $t {
    @if $inset {
      $l-string: $l-size 0 0 0 $l-color inset;
      $r-string: (-$r-size) 0 0 0 $r-color inset;
      $t-string: 0 $t-size 0 0 $t-color inset;
      $b-string: 0 (-$b-size) 0 0 $b-color inset;
    }

    @else {
      $l-string: (-$l-size) 0 0 0 $l-color;
      $r-string: $r-size 0 0 0 $r-color;
      $t-string: 0 (-$t-size) 0 0 $t-color;
      $b-string: 0 $b-size 0 0 $b-color;
    }

    // makes a comma separated list of the borders used
    // @todo: can probably use a map and a for to shorten
    @if $l !=null {
      $list: append($list, $l-string, $separator: comma);
    }

    @if $r !=null {
      $list: append($list, $r-string, $separator: comma);
    }

    @if $b !=null {
      $list: append($list, $b-string, $separator: comma);
    }

    @if $t !=null {
      $list: append($list, $t-string, $separator: comma);
    }
  }

  // all 4 sides
  @else {
    $string: null;

    @if $inset {
      $string: 0 0 0 $all-size $all-color inset;
    }

    @else {
      $string: 0 0 0 $all-size $all-color;
    }

    $list: append($list, $string);
  }

  @return $list;
}
