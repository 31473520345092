/*
       Bootstrap variables customized for our app.
       Only the vars we customize were copied from Bootstrap's
       variable file, here.
       \node_module\bootstrap\scss\_variables.scss

        When Bootstrap loads, it'll use these values
        instaed of its default values.

       https://getbootstrap.com/docs/4.4/getting-started/theming/

       NOTE: this must be loaded BEFORE bootstrap scss.
*/

// $headings-font-weight: 500;

$enable-shadows: false;
// $enable-rounded: false;
$grid-columns: 12;
$grid-gutter-width: 30px;

$box-shadow: 0 0.5rem 0.625rem rgba(0, 0, 0, 0.3);
