@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'mixins';
@import '_colors';
@import 'vars';

/*
  Overrides nbg multiselects

  @todo: Using @extends here so styles can also be used by
  the old style typeaheads in  _deprecated-type-aheads.scss

  Once that's deleted, the extended styles can come back here.
  And multiselect tag can also become it's own SCSS component
  to be used elsewhere in the app
*/

.multiselect__wrapper {
  @extend %multiselect-wrapper;
}

.multiselect__wrapper input {
  @extend %multiselect-input;
}

.multiselect__tag {
  @extend %multiselect-tag;

  .tag__text {
    position: relative;
    top: 1px;
  }

  .tag__close-icon-wrapper {
    padding: 0 0.6rem 0 0.5rem;
    cursor: pointer;

    .tag__close-icon {
      // optical alignment
      position: relative;
      top: 1px;

      font-size: 0.75rem;
      color: $tw--gray-800;
    }
  }
}

/*
  Typeahead dropdown. Ngb uses identical
  class names as a regular ngbDropdownMenu.
  These styles prevent conflicts when a multiselect is nested in a
  ngbDropdownMenu
*/
.multiselect__wrapper .dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 0;
  padding: 0.5rem 0;
  margin: 0.3rem 0 0;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;

  // the button
  .dropdown-item {
    @extend %remove-button-styling;

    width: 100%;
    padding: 0.5rem 1.5rem;
    font-family: $font-family--primary;
    font-size: px-to-rem(16px);
    font-weight: 400;
    color: $tw--black;
    white-space: pre-wrap;
  }

  // note we target both the pseudo active state, and when the active class is added
  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item:active,
  .dropdown-item.active {
    background-color: $tw--gray-300;
  }
}

ngb-typeahead-window {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  bottom: auto !important;
}
