// overriding all the bootstrap button styles
.card-menu-button.active,
.card-menu-button.active:active,
.card-menu-button.active:focus {
  background-color: $cq-neutral-200 !important;
  color: $cq-neutral-700 !important;
  border: $cq-neutral-300 1px solid !important;

  i {
    color: $cq-neutral-700 !important;
  }
}

.card-menu-button,
.card-menu-button:active,
.card-menu-button:focus {
  background-color: $tw--white !important;
  color: $cq-neutral-600 !important;
  border: $cq-neutral-300 1px solid !important;

  i {
    color: $cq-neutral-600 !important;
  }
}
