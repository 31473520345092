@import 'vars';
@import 'mixins';
@import '_colors';

/*
  For resetting HTML elements globally
*/
body {
  font-family: $font-family--primary;
  font-size: px-to-rem(16px);
  font-weight: 400;
}

h3 {
  font-family: $font-family--primary;
  font-size: px-to-rem(16px);
  font-weight: 500;
}

dt {
  border: 0;
  margin: 0;
  padding: 0;
}

ol,
ul,
dl {
  margin: 0;
  padding: 0;
}

a {
  color: $tw--blue;
}

// for the password strength meter
.progress-bar-success {
  @extend .bg-success;
}

.progress-bar-danger {
  @extend .bg-danger;
}

.progress-bar-warning {
  @extend .bg-warning;
}
