@import '../_colors';

$jobStatusGreen: $tw--green;
$jobStatusYellow: $tw--yellow;
$jobStatusRed: $tw--red;
$jobStatusOnHold: $tw--dark-gray;
$jobStatusOnHold2: $tw--gray;

.job-status-bar {
  width: 20px;
}

.job-status-bar.job-status-green {
  background-color: $jobStatusGreen;
}

.job-status-bar.job-status-yellow {
  background-color: $jobStatusYellow;
}

.job-status-bar.job-status-red {
  background-color: $jobStatusRed;
}

.job-status-bar.job-status-on-hold {
  background: repeating-linear-gradient(
    45deg,
    $jobStatusOnHold,
    $jobStatusOnHold 10px,
    $jobStatusOnHold2 10px,
    $jobStatusOnHold2 20px
  );
}