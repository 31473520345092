.modal-size {
  .modal-dialog {
    max-width: 90% !important;
  }
}

.modal-size-700 {
  .modal-dialog {
    max-width: 700px !important;
  }
}

.small-info-modal {
  twng-modal-header .header {
    padding: 1.25rem;
  }

  twng-modal-body .modal-body {
    padding: 2rem;
  }

  twng-modal-footer .modal-footer {
    padding: 1rem;
  }
}