.delete-wall-tab {
  left: 20px !important;
  top: -11px !important;;

  .popover-arrow.arrow {
    left: 40% !important;
  }
}

.delete-custom-dashboard {
  left: 20px !important;

  .popover-arrow.arrow {
    left: 38% !important;
  }
}
