@import 'vars';
@import 'mixins';
@import '_colors';

/*
  Our app's standard icon button. Apply it to any <button> or <div> element.
  Can also be extended into other buttons, like Bootstrap dropdown's ngbDropdownToggle
*/
.actions-button {
  @extend %remove-button-styling;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 2.2rem;
  height: 2.2rem;
  color: $tw--gray-700;
  border-radius: 50%;
  text-align: center;

  // @todo: redesign outline and show for accessibility
  outline: 0;
  outline-offset: 0;

  user-select: none;
  cursor: pointer;
  transition: background-color 0.2s;

  i {
    font-size: 1rem;
    color: $tw--gray-900;
  }

  svg {
    path {
      fill: $tw--gray-900;
    }
  }

  &:hover {
    background: $tw--gray-200;
    text-decoration: none;
  }
}

.actions-button--dark-bg {
  &:hover {
    background: $tw--gray-300;
  }
}


@mixin tw-button() {
  font-family: $font-family--secondary;
  text-transform: uppercase;
  border: 0;
  border-radius: 3px;
  color: white;
  background-color: $tw--blue;
  letter-spacing: 0.025rem;
  transition: background-color 0.2s;
  user-select: none;

  &:hover {
    background-color: lighten($tw--blue, 10%);
  }

  &[disabled] {
    background-color: lighten($tw--blue, 25%);

    &:hover {
      background-color: lighten($tw--blue, 25%);
    }
  }
}

.button--primary {
  @include tw-button();
}

.button--secondary {
  @include tw-button();
  color: $tw--gray-900;
  background-color: $tw--gray-300;

  &:hover {
    background-color: darken($tw--gray-300, 10%);
  }
}

.button--link {
  @include tw-button();
  color: $tw--blue;
  background: none;
  text-transform: none;

  &:hover {
    text-decoration: underline;
    background: none;
  }
}

.button--small {
  padding: 0.5rem 1rem;
  font-size: px-to-rem(14px);
}

.button--large {
  padding: 0.75rem 2rem;
  font-size: px-to-rem(16px);
}

.icon-divider {
  display: inline-block;
  margin: 0 0.6rem;
  width: 1px;
  height: 11px;
  background-color: $tw--gray-400;
}
