@import '_colors';
@import 'vars';

$nav-line-height: 3px;

.app__tab-outlet.tab-content, .tab-content {
  .tab-pane {
    border: 0;
    padding: 0;
  }
}

// bootstrap tab overrides
.app__tabs {

  .nav-tabs,
  &.nav-tabs {
    padding: 0 2rem;
    border: none;
    border-bottom: 1px solid #e1e1e1;
    font-family: $font-family--primary;
  
    .nav-item {
      margin-bottom: 0;

      .nav-link {
        padding: 1rem;
        border: none;
        color: $tw--gray-900;
        transition: all 0.05s ease-out;
        user-select: none;

        &.active {
          border-radius: 0;
          background: transparent;
          border: none;
          color: $tw--blue;

          /*
              Using border would push the element, use this instead
              Can't have negative SCSS var #'s, so multiply by -1
            */
          box-shadow: inset 0 calc(-1 * #{$nav-line-height}) 0 0 $tw--blue;
        }

        &:hover {
          border: none;
          border-radius: 0;

          /*
              Using border would push the element, use this instead
              Can't have negative SCSS var #'s, so multiply by -1
            */
          box-shadow: inset 0 calc(-1 * #{$nav-line-height}) 0 0 rgba($tw--blue, 0.8);
        }
      }
    }

    &.tabs-jumbo {
      font-size: 1.3rem;
      border-bottom: none;

      .nav-item .nav-link {
        padding: 0.9rem 1.4rem;
      }
    }
  }

  &.sub-tabs {
    background-color: $tw--light-blue-gray;
    border-bottom: none;
  }


}
