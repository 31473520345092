/*
  Style manifest:
    @imports only, no styles here
    Order matters
    No .scss ext needed
    _ in partial filenames
*/

/*
  Vendor libraries first
*/

// NOTE: variables must be loaded before bootstrap
@import 'vendor.overrides/bootstrap.variables';
@import 'bootstrap/scss/bootstrap';

@import 'ngx-toastr/toastr';

/*
  3rd party vendor overrides. Here, after vendor library imports,
  but before shared application styles
*/
@import 'vendor.overrides/toast';
@import 'vendor.overrides/bootstrap';
@import 'vendor.overrides/font.awesome';

@import 'reset';
@import 'utility';
@import 'mixins';
@import 'mq.debugger';
@import '_colors';
@import 'app';

@import 'components/tabs';
@import 'components/activity-labels';
@import 'components/header';
@import 'components/button';
@import 'components/dropdown';
@import 'components/job';
@import 'components/job-status-update';
@import 'components/badge';
@import 'components/table-sort';
@import 'components/job_stage_header';
@import 'components/spinner';
@import 'components/confirmation_popover';

@import 'components/multiselect';
@import 'components/select';
@import 'components/card-menu';
@import 'components/modal';
@import 'components/feature_highlight';
@import 'pdf_export';

.widget .header {
  border-radius: 5px 5px 0 0;
  border: none !important;
}

.chart-legend .legend-labels {
  white-space: pre-wrap !important;

  .legend-label {
    cursor: default;
  }
}

input[type="checkbox"]:checked {
  appearance: auto;
}

.dragging {
  position: relative;
  z-index: 1000;
  pointer-events: none;
}

.is-dragging {
  background-color: $cq-neutral-200;
}

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  margin-bottom: 0.5rem;
}

.popover {
  .popover-title.popover-header {
    font-size: 1.5rem;
  }

  .popover-arrow.arrow {
    position: absolute;
  }

  .confirm-btn-container .btn.btn-block {
    width: 100%;
  }

  .popover-content.popover-body p {
    padding-bottom: 6px;
  }
}
