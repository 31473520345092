@import 'mixins';
@import '_colors';

.btn {
  user-select: none;
}

.btn-primary {
  background-color: $tw--blue !important;
  color: white !important;
}

.btn-secondary {
  border-color: rgba($tw--blue, 0.5);
}

.btn:disabled {
  cursor: not-allowed;
}

.btn-light {
  border: 1px solid $tw--gray-400;
}

/*
  @todo: where possible:
    Refactor to Bootstrap variables, in _bootstrap.variables.scss
    Use Bootstrap mixins, ie, media queries, etc
*/
.container-fluid {
  width: 95%;
}

.alert-small {
  padding: 0.5rem 0.8rem;
  font-size: px-to-rem(14px);
}

/* bit of a hack so that the first job-stage of each job has a full border */
.col:first-of-type {
  border-left: 1px solid #ddd;
}

.modal.show .modal-dialog {
  display: flex;
  align-content: center;
  justify-content: center;
  width: auto;
  max-width: none;
  margin: 0;

  @include media-breakpoint-up(md) {
    margin: 3rem;
  }

  @include media-breakpoint-up(lg) {
    margin: 3rem auto;
    max-width: 1100px;
  }
}

.modal-content {
  min-height: 100vh;
  border: 0;

  .body {
    border-radius: 0.3rem;
  }

  @include media-breakpoint-up(md) {
    min-height: auto;
  }
}

.tooltip {
  display: flex;
  font-family: $font-family--primary;
  font-size: px-to-rem(14px);
  font-weight: 400;
}

.tooltip-inner {
  max-width: 370px;
}

.tab-pane {
  background-color: white;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 16px 0 16px;
  min-height: 110px;
}

.nav a {
  cursor: pointer;
}

.badge {
  font-family: $font-family--primary;
  font-size: px-to-rem(13px);
  font-weight: 400;
  user-select: none;
}

.badge-wire {
  color: #333;
  border: 1px solid #ddd;
}

.popover {
  width: auto;
  max-width: 500px;
  min-width: 170px;
}

.form-control {
  color: #222;
}
