@import 'mixins';
@import '_colors';

// This is here because we need to show
// hired job-stage.

.wall-job-stage-name {
  overflow: hidden;
  margin: 0;
  font-family: $font-family--secondary;
  font-weight: 400;
  line-height: 1;
  color: $tw--gray-700;
  text-overflow: ellipsis;
  user-select: none;
}

.job-count {
  overflow: visible;
  margin: 0;
  font-family: $font-family--secondary;
  font-weight: 400;
  line-height: 1;
  color: $tw--gray-700;
  user-select: none;
}

.hired-job-stage {

  .job-count,
  .wall-job-stage-name {
    color: #333;
  }
}
