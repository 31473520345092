/*
  Class is added to <body> by JS when IE11 is detected
  Define the flexbox children properties
  needed for flexbox layout to work,
*/

.ie11 {
  .grid-item {
    margin: 15px;
    min-width: 290px;
    width: calc((100% / 5) - 30px);
  }
}

.remove-button-styling {
  @extend %remove-button-styling;
}

.bg--light-blue-gray {
  background-color: $tw--light-blue-gray !important;
}

.rotate--270 {
  transform: rotate(270deg);
}

.pointer-events--none {
  pointer-events: none;
}

.up--1px {
  position: relative;
  top: -1px;
}

.overflow--hidden {
  overflow: hidden !important;
}

.min-width--auto {
  min-width: auto;
}

.display--none {
  display: none;
}

.clickable {
  cursor: pointer;
}

// .remove-number-arrows removes up/down buttons from number input
// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
.remove-number-arrows::-webkit-outer-spin-button,
.remove-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-number-arrows {
  -moz-appearance: textfield;
}

// use in combination with:
// {
//   width: 123px;
//   opacity: 1;
// }
// and the element will appear and expand in width
.collapsable-element:not(.uncollapsed) {
  width: 0px !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
}

.collapsable-element {
  transition: width 0.5s, opacity 0.1s;
}

// for some reason legends in charts, that are supposed to be right, get pulled
// bellow the chart. This pulls them back to right
ngx-charts-area-chart-normalized, ngx-charts-bar-vertical-stacked {
  ngx-charts-legend {
    float: right;
  }
}

.p-8 {
  padding: 3rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.px-6 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.mx-6 {
  margin-left: 2rem;
  margin-right: 2rem;
}